import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import pages from './sonede.json';
import useAPI from './api/API';
import { Modal } from 'antd';
import Courbe from './Courbe';
import { useRecoilState } from 'recoil';
import { layoutState } from './layout/LayoutState';
import { userState } from './auth/userState';

function Page() {
  const [user] = useRecoilState(userState);
  const [prevPage, setPrevPage] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useRecoilState(layoutState);
  const { Post } = useAPI();
  const [data, setData] = useState({
    djerba: {},
    zarzis: {},
    gabes: {},
    medenine: {},
  });
  const location = useLocation();
  const navigate = useNavigate();

  const initItem = p => (item) => {
    item.click = () => {
      if (item.popup === 'xml.php') {
        Modal.info({
          width: 800,
          icon: null,
          okText: 'Fermer',
          content: <Courbe key={item.var} item={item} region={p.region} />
        });
      } else if (item.href) {
        navigate(item.href);
      }
    }
    return item;
  }

  useEffect(() => {
    if (page) setPrevPage({ ...page });
    const p = pages.find(elm => elm.url === location?.pathname);
    if (p && p.region) {
      if (['zarzis', 'medenine', 'djerba'].includes(p.region) && !(user && user[p.region] === 'T')) {
        window.location = '/';
      }
    }
    if (p) {
      p.color = [...p.color].map(initItem(p));
      p.click = [...p.click].map(initItem(p));
      p.levels = [...p.levels].map(initItem(p));
      p.values = [...p.values].map(initItem(p));
    }
    setPage(p);
    setLoading(true);
    const controller = new AbortController();
    if (p) {
      Post('getRegionData', { region: p.region }, null, controller)
        .then(newData => {
          setData({ ...data, [p.region]: newData })
        })
        .catch(console.error);
    }
    setLayout(new Date);

    return () => {
      // if (p) controller.abort();
    }
  }, [location?.pathname]);

  if (!page) return (
    <div className="contentpage">
      <div className="conatiner">
        <div className='listbtn'>
          {user && user.zarzis === 'T' ? (<Link to="/zarzis/" className='btnacc'> شبكة جرجيس </Link>) : null}
          {user && user.medenine === 'T' ? (<Link to="/medenine/" className='btnacc'> شبكة مدنين </Link>) : null}
          {user && user.djerba === 'T' ? (<Link to="/djerba/" className='btnacc'> شبكة جربة </Link>) : null}
          {user && user.gabes === 'T' ? (<Link to="/gabes/" className='btnacc'> شبكة قابس </Link>) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div className="pagelayout">
      <h1 className='titlepage'>{page.titre}</h1>
      <div className="contentpage">
        <img src={page.image} alt="" onLoad={() => setLoading(false)} />
        {prevPage && loading && prevPage.color.map((item, i) => <ColorItem key={'item-' + i} item={item} data={data[prevPage.region]} />)}
        {prevPage && loading && prevPage.click.map((item, i) => <LinkItem key={'item-' + i} item={item} data={data[prevPage.region]} />)}
        {prevPage && loading && prevPage.levels.map((item, i) => <LevelItem key={'item-' + i} item={item} data={data[prevPage.region]} />)}
        {prevPage && loading && prevPage.values.map((item, i) => <ValItem key={'item-' + i} item={item} data={data[prevPage.region]} />)}

        {!loading && page.color.map((item, i) => <ColorItem key={'item-' + i} item={item} data={data[page.region]} />)}
        {!loading && page.click.map((item, i) => <LinkItem key={'item-' + i} item={item} data={data[page.region]} />)}
        {!loading && page.levels.map((item, i) => <LevelItem key={'item-' + i} item={item} data={data[page.region]} />)}
        {!loading && page.values.map((item, i) => <ValItem key={'item-' + i} item={item} data={data[page.region]} />)}
        {!loading && page.tablePosition ? <TableItem page={page} data={data[page.region]} /> : null}
        {(page && page.region && page.url !== `/${page.region}/`) ? (
          <div>
            <Link to={`/${page.region}/`}><img src='/images/Logout.png' alt='Logout' style={{ height: 75 }} /></Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function ColorItem({ item, data }) {
  const colors = {
    'vert.png': 'green',
    'jaune.png': 'yellow',
    'rouge.png': 'red',

    'g-vert.jpg': 'green',
    'g-jaune.jpg': 'yellow',
    'g-rouge.jpg': 'red',

    'bir-vert.jpg': 'green',
    'bir-jaune.jpg': 'yellow',
    'bir-rouge.jpg': 'red',
  };

  let img = null;
  switch (item.type) {
    case 'g-vert.jpg':
      img = 'g-rouge.jpg';
      if (parseInt(data[item.var])) {
        img = 'g-vert.jpg';
      } else if (item.image === 'g-vert.jpg') {
        img = 'g-vert.jpg';
      }
      return <span className="domitem coloritem cursor" data-var={item.var} data-val={data[item.var]} style={{ ...item.position }} onClick={item.click}><img alt="" src={`/images/${img}`} /></span>
    case 'bir-vert.jpg':
      img = 'bir-rouge.jpg';
      if (parseInt(data[item.var])) {
        img = 'bir-vert.jpg';
      }
      return <span className="domitem coloritem cursor" data-var={item.var} data-val={data[item.var]} style={{ ...item.position }} onClick={item.click}><img alt="" src={`/images/${img}`} /></span>
    default:
      img = 'red';
      if (parseInt(data[item.var])) {
        img = 'green';
      } else if (item.image === 'jaune.png') {
        img = 'yellow';
      } else if (item.image === 'vert.png') {
        img = 'red';
      }
      return <span className="domitem coloritem cursor" data-var={item.var} data-val={data[item.var]} style={{ ...item.position, backgroundColor: img }} onClick={item.click}></span>
  }
}

function LinkItem({ item, data }) {
  if (item.href) {
    return <Link to={item.href} data-var={item.var} className="domitem cursor" style={item.position} />
  } else {
    return <span className="domitem cursor" data-var={item.var} style={item.position} onClick={item.click} />
  }
}

function LevelItem({ item, data }) {
  const value = getValue1(item, data);
  if (!parseFloat(item.div)) return null;
  const p = Math.max((parseFloat(value) / parseFloat(item.div)) * 100, 0);
  return (
    <span className="domitem LevelItem cursor" data-var={item.var} data-val={value} data-dev={item.div} style={{ overflow: 'hidden' , ...item.position}} onClick={item.click}>
      <span style={{ height: p + '%' }} />
    </span>
  );
}

function ValItem({ item, data }) {
  return <span className="domitem cursor" style={item.position} onClick={item.click}>{getValue(item, data)}</span>
}

function TableItem({ page, data }) {
  let table = page.table;
  table = table.map(tr => Object.values(tr));
  const onClick = td => () => {
    if (td.var) {
      Modal.info({
        width: 800,
        icon: null,
        okText: 'Fermer',
        content: <Courbe key={td.var} item={td} region={td.region || page.region} />
      });
    }
  }
  return (
    <table className={`domitem cursor table-${page.region}`} border="1" style={page.tablePosition}>
      <tbody>
        {table.map((tr, i) => (
          <tr key={'tr' + i}>
            {tr.map((td, j) => (
              <td key={'td' + j} {...td} content={null} onClick={onClick(td)}>{getValue(td, data)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function getValue1(item, data) {
  let value = item.content || '';
  if (item.var) {
    if (item.region && data[item.region]) {
      value = data[item.region][item.var];
    } else {
      value = data[item.var];
    }
  }
  return value;
}

function getValue(item, data) {
  let value = item.content || '';
  let varName = '';
  let region = '';
  if (item.var) {
    varName = item.var;
    region = item.region || '';
    if (item.region && data[item.region]) {
      value = data[item.region][item.var];
    } else {
      value = data[item.var];
    }
    if (parseFloat(value) >= 10) {
      value = parseFloat(value).toFixed(2) * 1;
    } else if (parseFloat(value)) {
      value = parseFloat(value).toFixed(2) * 1;
    }
  }
  return <span data-var={varName} data-region={region}>{value || ''}</span>;
}

export default Page;

