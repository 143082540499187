import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useAPI from './api/API';
import { Button, Spin } from 'antd';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Courbe ({ item, region }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { Post } = useAPI();
  const today = new Date();
  let [state, setState] = useState({
    date_debut: (new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2)).toJSON().split("T")[0],
    date_fin: today.toJSON().split("T")[0],
  });

  state.set = (obj = {}) => {
    Object.assign(state, obj);
    setState({ ...state });
  }

  state.bind = (name) => {
    return {
      value: state[name] || '',
      onChange: e => {
        state.set({ [name]: e.target.value });
      }
    }
  }

  const loadData = () => {
    setLoading(true);
    Post("courbe", { ...item, region, ...state })
      .then((data) => {
        if (Array.isArray(data)) {
          setData(data);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    loadData();
  }, [item && item.var]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: item && item.titre,
      },
    },
  };
  const labels = data.map((elm) => elm.date);
  const dataa = {
    labels,
    datasets: [
      {
        label: item && item.titre,
        data: data.map((elm) => elm.value),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <div className='frm-curbe'>
        <div className='frm-input'>
          <label>Date debut</label>
          <input type="date" {...state.bind('date_debut')} />
        </div>
        <div className='frm-input'>
          <label>Date fin</label>
          <input type="date" {...state.bind('date_fin')} />
        </div>
        <Button type='primary' onClick={loadData}>OK</Button>
      </div>
      <hr />
      <Line
        options={options}
        data={dataa}
      />
    </Spin>
  );
}

export default Courbe;
