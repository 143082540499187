import Page from './page';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth, { useAuthToken } from './auth/Auth';
import Layout from './layout/Layout';
import Users from './auth/Users';

function App () {
  const { ready, user } = useAuthToken();
  if (!ready) return "Loading ...";

  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/*" element={<Navigate to="/auth" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<Navigate to="/" />} />
        {user.droit === 'T' ? <Route path="/users/*" element={<Layout><Users /></Layout>} /> : null}
        <Route path="*" element={<Layout><Page /></Layout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
