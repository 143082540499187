import React from 'react';
import { Link } from "react-router-dom";
import useAPI from './../api/API';
import { useRecoilState } from 'recoil';
import { userState } from './../auth/userState';
import { layoutState } from './LayoutState';

function Layout({ children }) {
  const [user] = useRecoilState(userState);
  const [layout] = useRecoilState(layoutState);
  const { call } = useAPI();
  return (
    <div className="layout">
      <div className="header">
        <div className="header-top container">
          <div className="titre_ville titre_ville-left">
            الإدارة المركزية للإنتاج<br />
            الإدارة الترابية للإنتاج بالجنوب الشرقي
          </div>
          <div className="middleinfos">
            <div className="middleinfos-content">
              <a href="/">
                <img src="/images/logo.png" alt="" />
              </a>
              <span className="titre_header">الشركة الوطنية لإستغلال و توزيع المياه</span>
              <span className="orange">
                <strong>
                  Bonjour {user && (user.nom + ' ' + user.prenom)}
                </strong>
              </span>
            </div>
          </div>

          <span className="titre_ville titre_ville-right"> نظام التحكم الآلي<br />
            لشبكة الجنوب التونسي مدنين</span>
        </div>
      </div>
      <div className="top-menu">
        <div className="container">
          <ul>
            <li className={window.location.pathname.split('/')[1] === '' ? 'active' : ''}><Link to="/">الرئيسية</Link></li>
            {user && user.zarzis === 'T' ? (<li className={window.location.pathname.split('/')[1] === 'zarzis' ? 'active' : ''} ><Link to="/zarzis/">شبكة جرجيس</Link></li>) : null}
            {user && user.medenine === 'T' ? (<li className={window.location.pathname.split('/')[1] === 'medenine' ? 'active' : ''} ><Link to="/medenine/">شبكة مدنين</Link></li>) : null}
            {user && user.djerba === 'T' ? (<li className={window.location.pathname.split('/')[1] === 'djerba' ? 'active' : ''} ><Link to="/djerba/">شبكة جربة</Link></li>) : null}
            {user && user.gabes === 'T' ? (<li className={window.location.pathname.split('/')[1] === 'gabes' ? 'active' : ''} ><Link to="/gabes/">شبكة قابس</Link></li>) : null}
          </ul>
          <ul>
            {user && user.droit === 'T' ? <li><Link to="/users/">Utilisateurs</Link></li> : null}
            <li onClick={() => call("setUser")}>Déconnecter</li>
          </ul>
        </div>
      </div>
      <main>
        {children}
      </main>
      <div className="footer"></div>
    </div>
  );
}

export default Layout;
