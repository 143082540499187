import React from 'react';
import useCRUD from './../api/Crud';

function Input({ label, type, style, ...props }) {
  let input = <input className='css-input' style={{ ...style }} {...props} />;
  switch (type) {
    case 'bool':
      input = (
        <select className='css-input' style={{ color: props.value === 'T' ? 'green' : 'red', ...style }} {...props}>
          <option style={{ color: 'green' }} value="T">Oui</option>
          <option style={{ color: 'red' }} value="F">Non</option>
        </select>
      )
      break;
    default:
      break;
  }
  return (
    <div className='inputfield'>
      {label ? <label>{label}</label> : null}
      {input}
    </div>
  )
}


function Users() {
  const { list, item, createNewItem, loading } = useCRUD({ api: 'users', initialLoad: true });

  return (
    <div className="contentpage">
      <div className="container" style={{ maxWidth: 500 }}>
        <div className='user-header'>
          <h2>Utilisateurs</h2>
          {item || loading || (Array.isArray(list) && list.length >= 15) ? null : <button className="myButton" onClick={() => createNewItem({ etat: 'T', droit: 'F', djerba: 'T', medenine: 'T', zarzis: 'T' })}>Ajouter</button>}
        </div>
        {item ? (
          <div>
            <div className="formlayout">
              <div className="actions action-eq">
                <Input {...item.bind('nom')} label="Nom" />
                <Input {...item.bind('prenom')} label="Prénom" />
              </div>
              <Input {...item.bind('poste')} label="Poste" />
              <div className="actions action-eq">
                <Input {...item.bind('login')} label="Login" />
                <Input {...item.bind('pws')} label="Mot de passe" />
              </div>
              <div className="actions">
                <Input {...item.bind('etat')} type="bool" label="Etat" />
                <Input {...item.bind('droit')} type="bool" label="Droit" />
              </div>
              <div className="actions">
                <Input {...item.bind('djerba')} type="bool" label="Djerba" />
                <Input {...item.bind('medenine')} type="bool" label="Medenine" />
                <Input {...item.bind('zarzis')} type="bool" label="Zarzis" />
                <Input {...item.bind('gabes')} type="bool" label="Gabes" />
              </div>
              {
                item.$saved ? (
                  <div className="actions">
                    <div className='alert-success'>Enregistrée</div>
                  </div>
                ) : (
                  <div className="actions">
                    <button className="myButton" onClick={item.saveAndClose}>Enregistrer</button>
                    <button className="annuler" onClick={item.close}>Annuler</button>
                  </div>
                )
              }
            </div>
          </div>
        ) : (
          <>
            <table className="blueTable">
              <tr>
                <th>Nom / Prénom</th>
                <th>Etat</th>
                <th>Droit</th>
                <th></th>
              </tr>
              {list.map(item => {
                return (
                  <tr key={item.id_user}>
                    <td>{item.nom} {item.prenom}</td>
                    <td align='center'><span className={"led led-" + item.etat} /></td>
                    <td align='center'><span className={"led led-" + item.droit} /></td>
                    <td style={{ width: 1 }}>
                      <div className='btnlist'>
                        <button className="btn1" onClick={item.edit}>Modifer</button>
                        <button className="btn1" onClick={item.deleteConfirm('Voulez vous vraiment supprimer cet enregistrement?')}>Supprimer</button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </table>
            <hr />
            <h2>Le journal des connexions</h2>
            <Logs />
          </>
        )}
      </div>
    </div>
  );
};


function Logs() {
  const { list } = useCRUD({ api: 'logs', initialLoad: true });
  return (
    <table className="blueTable">
      <tr>
        <th>Date</th>
        <th>Nom / Prénom</th>
        <th>IP</th>
      </tr>
      {list.map(item => {
        return (
          <tr key={item.id}>
            <td>{item.date}</td>
            <td>{item.nom} {item.prenom}</td>
            <td>{item.ip}</td>
          </tr>
        )
      })}
    </table>
  )
}


export default Users;
