import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from './userState';
import jwt_decode from "jwt-decode";
import './Auth.css';
import bg from './bg.jpg';
import useAPI from './../api/API';

function Auth () {
  let [state, setState] = useState({});
  let [status, setStatus] = useState({});
  const { Post } = useAPI();

  state.set = (obj = {}) => {
    Object.assign(state, obj);
    setState({ ...state });
  }

  state.bind = (name) => {
    let statusValue = '';

    if (status[name] === true) {
      statusValue = 'input-success';
    } else if ((status[name] && typeof status[name] === 'string') || status[name] === false) {
      statusValue = 'input-error';
    }

    return {
      value: state[name] || '',
      className: statusValue,
      onChange: e => {
        state.set({ [name]: e.target.value });
      }
    }
  }

  const connect = async () => {
    const { data, status } = await Post('auth', state);
    state.set({ ...data });
    setStatus({ ...status });
  }

  return (
    <div className="auth-page">
      <style>{`.auth-page{ background-image: url(${bg}); }`}</style>
      <div className="auth-form">
        <img alt="logo" src="/images/logo-sonede.png" />
        <div className='auth-text'>
          <div> الإدارة المركزية للإنتاج</div>
          <div>الإدارة الترابية للإنتاج بالجنوب الشرقي</div>
        </div>
        <div className="auth-input">
          <input type="text" placeholder="Login" {...state.bind('login')} />
          {status.login && typeof status.login === 'string' ? <small className="input-error-message">{status.login}</small> : null}
        </div>
        <div className="auth-input">
          <input type="password" placeholder="Mot de passe" {...state.bind('password')} />
          {status.password && typeof status.password === 'string' ? <small className="input-error-message">{status.password}</small> : null}
        </div>
        <button className="auth-btn" onClick={connect}>Connecter</button>
      </div>
    </div>
  )
}

export const useAuthToken = name => {
  const [user, setUser] = useRecoilState(userState);
  const [ready, setReady] = useState(false);
  const { define, Get } = useAPI();

  const setToken = (token) => {
    localStorage.setItem(name || 'access_token', token);
    setUser(jwt_decode(token));
  }

  const removeToken = () => {
    localStorage.removeItem(name || 'access_token');
    setUser(null);
  }

  define('setUser', (token) => {
    if (token) {
      setToken(token);
    } else {
      removeToken()
    }
  });

  useEffect(() => {
    const token = localStorage.getItem(name || 'access_token');
    if (token) {
      Get('checkToken')
        .then((valid) => {
          const u = jwt_decode(token);
          if (u && valid) {
            setUser(u);
          } else {
            removeToken();
          }
        })
        .catch(console.error)
        .finally(()=> {
          setTimeout(() => setReady(true), 100);
        });
    } else {
      removeToken();
      setTimeout(() => setReady(true), 100);
    }
  }, []);

  return { ready, user };
}

export default Auth;
