import { useMemo } from 'react';

const API_DEF_LIST = {};
const API_ENDPOINT = (process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_ENDPOINT_DEV : process.env.REACT_APP_API_ENDPOINT ) || '/api';
function getFullAPIPath (path, params, options) {
  let ENDPOINT = (options && options.ENDPOINT) || (options && options.API_ENDPOINT) || API_ENDPOINT
  if (`${ENDPOINT}`.trim().endsWith('/') && `${path}`.trim().startsWith('/')) {
    ENDPOINT = `${ENDPOINT}`.substring(0, `${ENDPOINT}`.length - 1);
  }
  ENDPOINT = ENDPOINT + path;
  let s = [];
  if (params) {
    Object.entries(params).forEach(([key, value]) => s.push(`${key}=${encodeURIComponent(value)}`));
  }
  if (s.length) {
    if (ENDPOINT.match(/\?/i)) {
      ENDPOINT += '&' + s.join('&');
    } else {
      ENDPOINT += '?' + s.join('&');
    }
  }
  return ENDPOINT;
}

function useAPI (options) {
  const Request = async (method, path, data = {}, params = {}, controller = null) => {
    const { signal } = controller || {};
    const token = localStorage.getItem('access_token');
    const authorizationObj = token ? { Authorization: 'Bearer ' + token } : null;
    let rawResponse;
    let bodyObj = {};
    if (`${method}`.trim().toLowerCase() !== 'get') {
      bodyObj = { body: JSON.stringify(data) };
    }
    if (signal) {
      bodyObj.signal = signal;
    }
    rawResponse = await fetch(getFullAPIPath(path, params, options), {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...authorizationObj
      },
      ...bodyObj
    });
    const content = await rawResponse.json();
    if (content && Array.isArray(content.head)) {
      content.head.forEach(item => {
        if (API_DEF_LIST[item.name]) {
          API_DEF_LIST[item.name](item.data);
        } else {
          console.log(item);
        }
      });
    }

    return content && content.body;
  }

  const Get = (path, params = {}, controller = null) => {
    return Request('GET', path, null, params, controller);
  }

  const Post = (path, data, params = {}, controller = null) => {
    return Request('POST', path, data, params, controller);
  }

  const Put = (path, data, params = {}, controller = null) => {
    return Request('PUT', path, data, params, controller);
  }

  const Patch = (path, data, params = {}, controller = null) => {
    return Request('PATCH', path, data, params, controller);
  }

  const Delete = (path, data, params = {}, controller = null) => {
    return Request('DELETE', path, data, params, controller);
  }


  const define = (name, callback) => {
    API_DEF_LIST[name] = callback;
  }

  const call = (name, data) => {
    if (API_DEF_LIST[name]) {
      return API_DEF_LIST[name](data);
    }
  }

  const API = useMemo(() => ({ Get, Post, Put, Patch, Delete, define, call }), []);

  return API;
}


export default useAPI;
