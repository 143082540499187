import useAPI from './API';
import { useState, useEffect } from 'react';

const useCRUD = ({ api, initialLoad, initialFilter, initItem }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(null);
  const [filter, setFilter] = useState(initialFilter || {});
  const { Post } = useAPI();

  const _initItem = (item) => {
    item.set = (obj = {}) => {
      Object.assign(item, obj || {});
      setList([...list]);
    };

    item.edit = () => {
      setCurrent(item);
    }

    item.close = () => {
      setCurrent(null);
    }

    item.bind = (name) => {
      return {
        value: item[name] || '',
        style: { borderColor: (item._status && (item._status[name] === false || typeof item._status[name] === 'string')) ? 'red' : null },
        onChange: e => {
          item.set({ [name]: e.target.value });
        }
      }
    }

    item.save = async () => {
      try {
        item.set({ _loading: true });
        const result = await Post('users', { action: 'save', item });
        if (result && result.item) item.set(result.item);
        item.set({ _status: result && result.status });
        item.set({ _loading: false });
        return result && result.saved;
      } catch (error) {
        item.set({ _loading: false });
        console.log(error);
      }
      return false;
    }

    item.saveAndClose = async () => {
      const result = await item.save();
      if (result) {
        item.set({ $saved: true });
        setTimeout(() => {
          item.set({ $saved: false });
          item.close();
          loadData(1);
        }, 1500);
      }
      return result;
    }

    item.delete = async () => {
      try {
        item.set({ _loading: true });
        const { deleted } = await Post('users', { action: 'delete', item });
        if (deleted) {
          setCurrent(null);
          loadData(1);
        } else {
          item.set({ _loading: false });
        }
        return deleted;
      } catch (error) {
        item.set({ _loading: false });
        console.log(error);
      }
      return false;
    }

    item.deleteConfirm = (message) => () => {
      if (window.confirm(message)) {
        return item.delete();
      }
    }


    if (initItem) initItem(item);
    return item;
  }

  const loadData = async (page) => {
    setLoading(true);
    try {
      const { data } = await Post(api, { filter, page })
      if (Array.isArray(data)) {
        setList(data.map(_initItem));
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (initialLoad) {
      loadData(1);
    }
  }, [initialLoad]);

  const createNewItem = (data = {}) => {
    const item = _initItem({ ...data });
    setList([...list, item]);
    item.edit();
  }

  return { list: list.map(_initItem), loadData, setFilter, item: current, loading, createNewItem };
}

export default useCRUD;
